<template>
  <div>
    <va-modal id="acrsNotas"
      v-model="showModal"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2 style="text-align: right;color: #878787;">Observações sobre o aluno</h2>
      </template>
      <slot>
        <div class="row" v-if="acesso.editar">
          <fieldset>
            <p><strong> Esse questionário se refere a qual tipo de pontuação de notas?</strong></p>
            <va-radio
              option="1"
              v-model="formAlunoComNEE.tipo"
              @change="verificarEditarNee(1)"
              label="Simulado"
            />
            <va-radio
              option="2"
              v-model="formAlunoComNEE.tipo"
              @change="verificarEditarNee(2)"

              label="Atividade diversificada"
            />
            <va-radio
              option="3"
              v-model="formAlunoComNEE.tipo"
              @change="verificarEditarNee(3)"
              @click="verificarEditar"
              label="Avaliação"
            />
          </fieldset>
          <div class="col-md-12" v-if="formAlunoComNEE.tipo">
            <fieldset>
              <br><br>
              <p><strong>Para realizar a atividade o aluno necessitou de:</strong></p>
              <va-radio
                option="0"
                label="Pouco suporte"
                v-model="formAlunoComNEE.suporte"

              />
              <va-radio
                option="1"
                label="Muito Suporte"
                v-model="formAlunoComNEE.suporte"
              />
              <va-radio
                option="2"
                label="Nenhum Suporte"
                v-model="formAlunoComNEE.suporte"
              />
              </fieldset>
              <fieldset>
                <br><br>
                <p><strong>Durante a realização da atividade o aluno teve:</strong></p>
                <va-radio
                  option="0"
                  label="Pouca compreeensão"
                  v-model="formAlunoComNEE.compreensao"

                />
                <va-radio
                  option="1"
                  label="Alta compreeensão"
                  v-model="formAlunoComNEE.compreensao"
                />
              </fieldset>
          </div>
          <div class="form-group" v-if="formAlunoComNEE.tipo">
            <label for="comment" style="padding-top:15px"><strong>Metodologia utilizada(descrever como foi realizada a avaliação)</strong></label>
            <textarea class="form-control" rows="4"  v-model="formAlunoComNEE.metodologia_utilizada"></textarea>
          </div>
          <div class="form-group" v-if="formAlunoComNEE.tipo">
            <label for="comment" style="padding-top:15px"><strong>Qual o diagnóstico pedágogico do estudante nessa habilidade?(descrever potenciais e desafios)</strong></label>
            <textarea class="form-control" rows="4"  v-model="formAlunoComNEE.diagnostico_pedagogico"></textarea>
          </div>
          <div class="form-group" v-if="formAlunoComNEE.tipo">
            <label for="comment" style="padding-top:15px"><strong>Observação:</strong></label>
            <textarea class="form-control" rows="4" v-model="formAlunoComNEE.observacao"></textarea>
          </div>
        </div>
        <div class="row" v-else>
          <div class="alert alert-success">
            Usuário não Autorizado!
          </div>
        </div>
      </slot>
      <br>
      <div class='col-md-12'>
        <button class="btn btn-primary" @click="alunocomNEE(0)" v-if="formAlunoComNEE.tipo != '' && formAlunoComNEE.suporte != '' && formAlunoComNEE.compreensao != '' && formAlunoComNEE.metodologia_utilizada != '' && formAlunoComNEE.diagnostico_pedagogico != '' && paraEditarNEE == 0">Enviar Informações</button>
        <button class="btn btn-primary" @click="alunocomNEE(1)" v-if="formAlunoComNEE.tipo != '' && formAlunoComNEE.suporte != '' && formAlunoComNEE.compreensao != '' && formAlunoComNEE.metodologia_utilizada != '' && formAlunoComNEE.diagnostico_pedagogico != '' && paraEditarNEE == 1">Editar Informações</button>
        <button v-if="formAlunoComNEE.tipo" class="btn btn-danger" style="float:right;" @click="showModal = !showModal">Voltar</button>
      </div>
    </va-modal>
  </div>
</template>

<script>
export default {
  name: "questionarioNEE",
  props:{
    //nee
    acesso:null,
    formAlunoComNEE: null,
    showModal:false,
    paraEditarNEE:0,
  },
  data() {
    return {
    };
  },
  methods: {
    verificarEditarNee(n){
      this.$emit('verificarEditarNee', n);
    },
    alunocomNEE(n){
      this.$emit('alunocomNEE', n);
    },


  },
  beforeMount() {
  },
}
</script>

<style>

</style>
