<template>
  <!-- Todos tem simulado, atividade e avaliacao-->
  <section v-if="final == false && Object.keys(this.configuracao).length != 0" class="resp-notatable">
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <!-- todos -->
            <th class="th_per1">MATRÍCULA</th>
            <th class="th_per1">NOME DO ALUNO</th>
            <th class="th_per1 th_per1-porcent"  v-if="this.configuracao.info.tera_simulado"
              style="text-transform: uppercase;">{{ this.configuracao.info.simulado_nome_exib }}<br class="hide-nota">(NOTA ENTRE 0-{{this.configuracao.info.notas.simulado}})</th>

            <th class="th_per1 th_per1-porcent"  v-if="this.configuracao.info.tera_atividades"
              style="text-transform: uppercase;">{{ this.configuracao.info.atividades_nome_exib }}<br class="hide-nota"> (NOTA ENTRE 0-{{this.configuracao.info.notas.atividades}})</th>

            <th class="th_per1 th_per1-porcent"  v-if="this.configuracao.info.tera_avaliacao"
              style="text-transform: uppercase;">{{ this.configuracao.info.avaliacao_nome_exib }}<br class="hide-nota"> (NOTA ENTRE 0-{{this.configuracao.info.notas.avaliacao}})</th>

            <th class="th_per1 th_per1-porcent"  v-if="tipoTabela == 2 && estiloTabelaEJA == null && this.configuracao.info.tera_recuperacao">RECUPERAÇÃO PARALELA <br class="hide-nota">(NOTA ENTRE 0-{{this.configuracao.info.notas['recuperacao_nota_b'+periodoSelecionado]}})</th>
            <!-- fund1 e fund2 -->
            <th class="th_per1" v-if="tipoTabela == 1 && estiloTabelaEJA == null">TOTAL</th>
            <th class="th_per1" v-if="tipoTabela == 2 && estiloTabelaEJA == null">MÉDIA</th>
            <th class="th_per1 th_per1-porcent" v-if="tipoTabela == 1 && periodoSelecionado == 2 && estiloTabelaEJA == null  && this.configuracao.info.tera_recuperacao" >RECUPERAÇÃO SEMESTRAL(NOTA ENTRE 0-{{this.configuracao.info.notas.recuperacao_nota_s1}})</th>
            <th class="th_per1 th_per1-porcent" v-if="tipoTabela == 1 && periodoSelecionado == 4 && estiloTabelaEJA == null  && this.configuracao.info.tera_recuperacao" >RECUPERAÇÃO SEMESTRAL(NOTA ENTRE 0-{{this.configuracao.info.notas.recuperacao_nota_s2}})</th>
            <th class="th_per1" v-if="tipoTabela == 1 && (periodoSelecionado == 2 || periodoSelecionado == 4) && estiloTabelaEJA == null">MÉDIA SEMESTRAL</th>
            <!--eja -->
            <th class="th_per1" v-if="estiloTabelaEJA != null">TOTAL</th>
            <th class="th_per1 th_per1-porcent" v-if="tipoTabela == 2 && estiloTabelaEJA != null">RECUPERAÇÃO PARALELA <br class="hide-nota">(NOTA ENTRE 0-{{this.configuracao.info.notas['recuperacao_nota_b'+periodoSelecionado]}})</th>

            <th class="th_per1" v-if="estiloTabelaEJA != null && tipoTabela == 2">MÉDIA</th>

            <th class="th_per1 th_per1-porcent"  v-if="estiloTabelaEJA != null && tipoTabela == 1 && periodoSelecionado == 2  && this.configuracao.info.tera_recuperacao">RECUPERAÇÃO SEMESTRAL <br class="hide-nota">(NOTA ENTRE 0-{{this.configuracao.info.notas.recuperacao_nota_s1}})</th>
            <th class="th_per1" v-if="estiloTabelaEJA != null && tipoTabela == 1 && periodoSelecionado == 2">MÉDIA SEMESTRAL</th>
            <th class="th_per1" v-if="estiloTabelaEJA != null && periodoSelecionado == 2">RECUPERAÇÃO FINAL(NOTA ENTRE 0-100)</th>
            <th class="th_per1" v-if="estiloTabelaEJA != null && periodoSelecionado == 2">MÉDIA FINAL</th>

          </tr>
        </thead>
        <tbody>
          <tr v-for="(record, index) of displayedRecords" :key="index">
            <!-- /////////////////////////////////////////////////////////////////// - Todos - ///////////////////////////////////////////////////////////////-->
            <td class="tdmt">{{record.matricula}}</td>
            <td>
              <div class="container" >
                <div class="row" >
                  <div class="col-md-12" style="padding-left: 0px;padding-right: 0px;" >
                    <p class="nome-nota-resp">
                      {{record.aluno_completo.nome}} {{record.aluno_completo.sobrenome}}



                      <!-- <div v-if="record.entrada_saida_aluno !== undefined && record.entrada_saida_aluno.length"> -->
                      <span v-if="record.entrada_saida_aluno !== undefined && record.entrada_saida_aluno.length">
                        <!-- <u>
                          {{record.entrada_saida_aluno[record.entrada_saida_aluno.length - 1].evento}}: {{record.entrada_saida_aluno[record.entrada_saida_aluno.length - 1].data_evento_br}}
                        </u> -->
                        <i
                          style="font-size: 20px; color: #ffd233;margin: 10px 0px 0px 10px;"
                          class="fa fa-info-circle"
                          :title="record.entrada_saida_aluno[record.entrada_saida_aluno.length - 1].evento+': '+ record.entrada_saida_aluno[record.entrada_saida_aluno.length - 1].data_evento_br">
                        </i>
                      </span>

                      <span v-if="record.entrada_saida_aluno !== undefined && record.entrada_saida_aluno.length > 1"
                        @click="buscarDadosDeEntradaESaidaAluno(record.entrada_saida_aluno)">
                        <i class="fa fa-exchange"
                          style="font-size: 20px; color: #3369ff;margin: 10px 0px 0px 10px; cursor: pointer;"
                          :title="'Entrada e Saída de Turma'">
                        </i>
                      </span>
                      <span v-if="record.entrada_saida_aluno !== undefined && record.entrada_saida_aluno.length && record.turma_aluno.ativa"
                        @click="buscarNotasHistorico(record)"
                      >
                        <i class="fa fa-history"
                          style="font-size: 20px; color: #3369ff;margin: 10px 0px 0px 10px; cursor: pointer;"
                          title="Nota(s) em outra(s) turma(s)" >
                        </i>
                      </span>
                      <!-- </div> -->
                      <!-- <pm-Button v-if="record.entrada_saida_aluno !== undefined && record.entrada_saida_aluno.length > 1" type="button" icon="pi pi-sync"
                        title="Entrada e Saída de Turma" class="p-button-rounded p-button-info "  @click="buscarDadosDeEntradaESaidaAluno(record.entrada_saida_aluno)">
                      </pm-Button>

                      <pm-Button v-if="record.entrada_saida_aluno !== undefined && record.entrada_saida_aluno.length && record.turma_aluno.ativa" type="button" icon="pi pi-calendar"

                        title="Nota(s) em outra(s) turma(s)" class="p-button-rounded p-button-info"  @click="buscarNotasHistorico(record)">
                      </pm-Button> -->





                      <span v-if="record.evento_saida[record.evento_saida.length - 1].tipoEvento == 'Transferido' && record.aluno_fora_da_turma == true">
                        <i
                          style="font-size: 20px; color: #ffd233;margin: 10px 0px 0px 10px;"
                          class="fa fa-info-circle"
                          :title=" 'Transferido - ' + record.evento_saida[record.evento_saida.length - 1].data_formato_br">
                        </i>

                      </span>
                      <span v-if="record.evento_saida[record.evento_saida.length - 1].tipoEvento == 'Evadido' && record.aluno_fora_da_turma == true"  >
                        <i
                          style="font-size: 20px; color: #ffd233;margin: 10px 0px 0px 10px;"
                          class="fa fa-info-circle"
                          :title=" 'Evadido'">
                        </i>
                      </span>
                      <span v-if="record.evento_saida[record.evento_saida.length - 1].tipoEvento == 'Faleceu' && record.aluno_fora_da_turma == true"  >
                        <i
                          style="font-size: 20px; color: #ffd233;margin: 10px 0px 0px 10px;"
                          class="fa fa-info-circle"
                          :title="'Falecido'">
                        </i>
                      </span>
                      <span v-if="record.evento_saida[record.evento_saida.length - 1].tipoEvento == 'Transferencia de Turma' && record.aluno_fora_da_turma == true"  >
                        <i
                          style="font-size: 20px; color: #ffd233;margin: 10px 0px 0px 10px;"
                          class="fa fa-info-circle"
                          :title="' Mudou de turma - '+record.evento_saida[record.evento_saida.length - 1].data_formato_br">
                        </i>
                      </span>

                      <!-- <va-popover style="cursor: pointer;" class="badge bg-danger table_style ml-2"
                            :message="'Mudou para turma: ' + record.evento_saida[record.evento_saida.length-1].turma_nova.nome" color="danger"
                            v-if="record.evento_saida[record.evento_saida.length - 1].tipoEvento == 'Transferencia de Turma'">
                            Mudou de turma - {{record.evento_saida[record.evento_saida.length - 1].data_formato_br}}
                          </va-popover>/ -->

                      <span class="badge"  v-if="record.nee">
                        <va-popover
                          class="mr-1 mb-1"
                          message="Adicionar métodos utilizados para avaliar o aluno com NEE."
                          color="primary"
                          placement="right"
                        >
                          <va-button v-if="acesso.cadastrar" style="background:transparent;border:none!important;" @click="ajustarInfo(record.aluno_id)">
                            PCD
                          </va-button>
                          <va-button v-else color="#4589df00" >
                            PCD
                          </va-button>
                        </va-popover>
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </td>
            <td class="width-10"  v-if="this.configuracao.info.tera_simulado">
              <div  v-if="isLoadingPage" style="display: flex;">
                <pm-ProgressSpinner style="align-items: center;width:50px;height:50px;" />
              </div>
              <div v-else>
                <div v-if="(periodoSelecionado == 1 &&  record.aluno_notas_completa.periodos_bloqueados >= 1) ||
                  (periodoSelecionado == 2 && record.aluno_notas_completa.periodos_bloqueados >= 2) ||
                  (periodoSelecionado == 3 && record.aluno_notas_completa.periodos_bloqueados >= 3) ||
                  (periodoSelecionado == 4 && record.aluno_notas_completa.periodos_bloqueados >= 4)">
                  <input type="text" disabled :value="record['simuladoPer'+periodoSelecionado]" class="form-control"/>
                </div>
                <div v-else-if="record['periodo'+periodoSelecionado+'_simulado'] == null">
                  <input v-if="!record.aluno_fora_da_turma && acesso.cadastrar"
                    :disabled="encerrado['p'+periodoSelecionado] == 1 || !anoSitucao"
                    min="0" :max="this.configuracao.info.notas.simulado"
                    @blur="adicionarEditarSimulado(record.aluno_id, record['simuladoPer'+periodoSelecionado], 1, null, this.configuracao, periodoSelecionado )"
                    style="background-color: #5f9e8e38;"
                    type="number"
                    class="form-control"
                    v-model="record['simuladoPer'+periodoSelecionado]"
                    placeholder="Digite a nota"
                  >
                  <input type="text" disabled :value="record['simuladoPer'+periodoSelecionado]" class="form-control" v-else/>
                </div>
                <div v-else>
                  <input v-if="!record.aluno_fora_da_turma && acesso.editar"
                    :disabled="encerrado['p'+periodoSelecionado] == 1 || !anoSitucao"
                    min="0" :max="this.configuracao.info.notas.simulado"
                    @blur="adicionarEditarSimulado(record.aluno_id, record['simuladoPer'+periodoSelecionado], 2,  record['periodo'+periodoSelecionado+'_simulado']['id'], this.configuracao, periodoSelecionado)"
                    style="background-color:rgb(224 214 216);"
                    type="number"
                    class="form-control"
                    v-model="record['simuladoPer'+periodoSelecionado]"
                    placeholder="Digite a nota"
                  >
                  <input type="text" disabled :value="record['simuladoPer'+periodoSelecionado]" class="form-control" v-else/>
                </div>
              </div>
            </td>
            <td class="width-10"  v-if="this.configuracao.info.tera_atividades">
              <div  v-if="isLoadingPage" style="display: flex;">
                <pm-ProgressSpinner style="align-items: center;width:50px;height:50px;" />
              </div>
              <div v-else>
                <div v-if="(periodoSelecionado == 1 && record.aluno_notas_completa.periodos_bloqueados >= 1) ||
                  (periodoSelecionado == 2 && record.aluno_notas_completa.periodos_bloqueados >= 2) ||
                  (periodoSelecionado == 3 && record.aluno_notas_completa.periodos_bloqueados >= 3) ||
                  (periodoSelecionado == 4 && record.aluno_notas_completa.periodos_bloqueados >= 4)">
                  <input type="text" disabled :value="record.ativPer" class="form-control"/>

                </div>
                <div v-else-if="record['periodo'+periodoSelecionado+'_atividades'] == null">
                  <input v-if="!record.aluno_fora_da_turma && acesso.cadastrar"
                    :disabled="encerrado['p'+periodoSelecionado] == 1 || !anoSitucao"

                    min="0" :max="this.configuracao.info.notas.atividades"
                    @blur="adicionarEditarAtividade(record.aluno_id, record['ativPer'+periodoSelecionado], 1, null, this.configuracao, periodoSelecionado)"
                    style="background-color: #5f9e8e38;"
                    type="number"
                    class="form-control"
                    v-model="record['ativPer'+periodoSelecionado]"
                    placeholder="Digite a nota"
                  >
                  <input type="text" disabled :value="record.ativPer" class="form-control" v-else/>
                </div>
                <div v-else>
                  <input v-if="!record.aluno_fora_da_turma && acesso.editar"
                    :disabled="encerrado['p'+periodoSelecionado] == 1 || !anoSitucao"
                    min="0" :max="this.configuracao.info.notas.atividades"
                    @blur="adicionarEditarAtividade(record.aluno_id, record['ativPer'+periodoSelecionado], 2, record['periodo'+periodoSelecionado+'_atividades'][0]['id'], this.configuracao, periodoSelecionado)"
                    style="background-color:rgb(224 214 216);"
                    type="number"
                    class="form-control"
                    v-model="record['ativPer'+periodoSelecionado]"
                    placeholder="Digite a nota"
                  >
                  <input type="text" disabled :value="record.ativPer" class="form-control" v-else/>
                </div>
              </div>
            </td>
            <td class="width-10"  v-if="this.configuracao.info.tera_avaliacao">
              <div  v-if="isLoadingPage" style="display: flex;">
                <pm-ProgressSpinner style="align-items: center;width:50px;height:50px;" />
              </div>
              <div v-else>

                <div v-if="(periodoSelecionado == 1 && record.aluno_notas_completa.periodos_bloqueados >= 1) ||
                  (periodoSelecionado == 2 && record.aluno_notas_completa.periodos_bloqueados >= 2) ||
                  (periodoSelecionado == 3 && record.aluno_notas_completa.periodos_bloqueados >= 3) ||
                  (periodoSelecionado == 4 && record.aluno_notas_completa.periodos_bloqueados >= 4)">
                  <input type="text" disabled :value="record['avaliacaoPer'+periodoSelecionado]" class="form-control"/>
                </div>
                <div v-else-if="record['periodo'+periodoSelecionado+'_avaliacao'] == null">
                  <input v-if="!record.aluno_fora_da_turma && acesso.cadastrar"
                    :disabled="encerrado['p'+periodoSelecionado] == 1 || !anoSitucao"
                    min="0" :max="this.configuracao.info.notas.avaliacao"
                    @blur="adicionarEditarAvaliacao(record.aluno_id,  record['avaliacaoPer'+periodoSelecionado], 1, null, this.configuracao, periodoSelecionado)"
                    style="background-color: #5f9e8e38;"
                    type="number"
                    class="form-control"
                    v-model="record['avaliacaoPer'+periodoSelecionado]"
                    placeholder="Digite a nota"
                  >
                  <input type="text" disabled :value="record['avaliacaoPer'+periodoSelecionado]" class="form-control" v-else/>
                </div>
                <div v-else>
                  <input v-if="!record.aluno_fora_da_turma && acesso.editar"
                    :disabled="encerrado['p'+periodoSelecionado] == 1 || !anoSitucao"
                    min="0" :max="this.configuracao.info.notas.avaliacao"
                    @blur="adicionarEditarAvaliacao(record.aluno_id, record['avaliacaoPer'+periodoSelecionado], 2, record['periodo'+periodoSelecionado+'_avaliacao']['id'], this.configuracao, periodoSelecionado)"
                    style="background-color:rgb(224 214 216);"
                    type="number"
                    class="form-control"
                    v-model="record['avaliacaoPer'+periodoSelecionado]"
                    placeholder="Digite a nota"
                  >
                  <input type="text" disabled :value=" record['avaliacaoPer'+periodoSelecionado]" class="form-control" v-else/>
                </div>
              </div>
            </td>


            <!-- /////////////////////////////////////////////////////////////////// - Fund1 e fund2 - ///////////////////////////////////////////////////////////////-->

            <!--Recuperacao bimestral, fundamental 1 e fundamental 2 -->
            <td class="width-10"   v-if="tipoTabela == 2 && estiloTabelaEJA == null  && this.configuracao.info.tera_recuperacao">
              <div  v-if="isLoadingPage" style="display: flex;">
                <pm-ProgressSpinner style="align-items: center;width:50px;height:50px;" />
              </div>
              <div v-else>

                <div v-if="(periodoSelecionado == 1 && record.aluno_notas_completa.periodos_bloqueados >= 1) ||
                  (periodoSelecionado == 2 && record.aluno_notas_completa.periodos_bloqueados >= 2) ||
                  (periodoSelecionado == 3 && record.aluno_notas_completa.periodos_bloqueados >= 3) ||
                  (periodoSelecionado == 4 && record.aluno_notas_completa.periodos_bloqueados >= 4)">
                  <input type="text" disabled :value="record['recuperacaoPer'+periodoSelecionado]" class="form-control" />
                </div>
                <div v-else-if="record['periodo'+periodoSelecionado+'_recuperacao'] == null">
                  <input
                    v-if="!record.aluno_fora_da_turma && acesso.editar"
                    :disabled="encerrado['p'+periodoSelecionado] == 1 || !anoSitucao"
                    min="0" :max="this.configuracao.info.notas['recuperacao_nota_b'+periodoSelecionado]"
                    @blur="adicionarEditarRecuperacao(record.aluno_id, record['recuperacaoPer'+periodoSelecionado], 1, null, this.configuracao, periodoSelecionado)"
                    style="background-color: #5f9e8e38;"
                    type="number"
                    class="form-control"
                    v-model="record['recuperacaoPer'+periodoSelecionado]"
                    placeholder="Digite a nota"
                  >
                  <input type="text" disabled :value="record['recuperacaoPer'+periodoSelecionado]" class="form-control" v-else/>
                </div>
                <div v-else>
                  <input v-if="!record.aluno_fora_da_turma && acesso.editar"
                    :disabled="encerrado['p'+periodoSelecionado] == 1 || !anoSitucao"
                    min="0" :max="this.configuracao.info.notas['recuperacao_nota_b'+periodoSelecionado]"
                    @blur="adicionarEditarRecuperacao(record.aluno_id, record['recuperacaoPer'+periodoSelecionado], 2,  record['periodo'+periodoSelecionado+'_recuperacao']['id'], this.configuracao, periodoSelecionado)"
                    style="background-color:rgb(224 214 216);"
                    type="number"
                    class="form-control"
                    v-model="record['recuperacaoPer'+periodoSelecionado]"
                    placeholder="Digite a nota"
                  >
                  <input type="text" disabled :value="record['recuperacaoPer'+periodoSelecionado]" class="form-control" v-else/>
                </div>
              </div>
            </td>

            <!-- Total e media, fundamental 1 e fundamental 2 -->
            <td v-if="estiloTabelaEJA == null">
              <div v-if="(periodoSelecionado == 1 && record.mostrarPeriodo1 ) ||
                (periodoSelecionado == 2 && record.mostrarPeriodo2 ) || (periodoSelecionado == 3 && record.mostrarPeriodo3 )
                || (periodoSelecionado == 4 && record.mostrarPeriodo4 ) ">
                <p v-if="record['media'+periodoSelecionado] >= this.configuracao.info.notas.media" style="text-align: center;color: #5a79ec;font-weight: bold;font-size: 18px;">
                  {{record['media'+periodoSelecionado]}}
                </p>
                <p v-if="record['media'+periodoSelecionado] < this.configuracao.info.notas.media"  style="text-align: center;color: #e60e0e;font-weight: bold;font-size: 18px;">
                  {{record['media'+periodoSelecionado]}}
                </p>
              </div>
            </td>

            <!--Recuperacao Semestral, fundamental 1 e fundamental 2 -->
            <td class="width-10"   v-if="tipoTabela == 1 && (periodoSelecionado == 2 || periodoSelecionado == 4) && estiloTabelaEJA == null  && this.configuracao.info.tera_recuperacao">
              <div  v-if="isLoadingPage" style="display: flex;">
                <pm-ProgressSpinner style="align-items: center;width:50px;height:50px;" />
              </div>
              <div v-else>

                <div v-if="(periodoSelecionado == 1 && record.aluno_notas_completa.periodos_bloqueados >= 1) ||
                  (periodoSelecionado == 2 && record.aluno_notas_completa.periodos_bloqueados >= 2) ||
                  (periodoSelecionado == 3 && record.aluno_notas_completa.periodos_bloqueados >= 3) ||
                  (periodoSelecionado == 4 && record.aluno_notas_completa.periodos_bloqueados >= 4)">
                  <input type="text" disabled :value="record['recuperacaoSemestre'+(periodoSelecionado == 2 ? 1 : 2)]" class="form-control"/>
                </div>
                <div v-else-if="record['periodo'+periodoSelecionado+'_recuperacao'] == null">
                  <input
                    v-if="!record.aluno_fora_da_turma && !record.nee && acesso.cadastrar"
                    :disabled="encerrado['p'+periodoSelecionado] == 1 || !anoSitucao"
                    min="0" max="100"
                    @blur="adicionarEditarRecuperacao(record.aluno_id,  record['recuperacaoSemestre'+(periodoSelecionado == 2 ? 1 : 2)], 1, null, this.configuracao, periodoSelecionado)"
                    style="background-color: #5f9e8e38;"
                    type="number"
                    class="form-control"
                    v-model="record['recuperacaoSemestre'+(periodoSelecionado == 2 ? 1 : 2)]"
                    placeholder="Digite a nota"
                  >
                  <input type="text" disabled :value="record['recuperacaoSemestre'+(periodoSelecionado == 2 ? 1 : 2)]" class="form-control" v-else/>
                </div>
                <div v-else>
                  <input v-if="!record.aluno_fora_da_turma && acesso.editar"
                    :disabled="encerrado['p'+periodoSelecionado] == 1 || !anoSitucao"
                    min="0" max="100"
                    @blur="adicionarEditarRecuperacao(record.aluno_id, record['recuperacaoSemestre'+(periodoSelecionado == 2 ? 1 : 2)], 2,  record['periodo'+periodoSelecionado+'_recuperacao']['id'], this.configuracao, periodoSelecionado)"
                    style="background-color:rgb(224 214 216);"
                    type="number"
                    class="form-control"
                    v-model="record['recuperacaoSemestre'+(periodoSelecionado == 2 ? 1 : 2)]"
                    placeholder="Digite a nota"
                  >
                  <input type="text" disabled :value="record['recuperacaoSemestre'+(periodoSelecionado == 2 ? 1 : 2)]" class="form-control" v-else/>
                </div>
              </div>
            </td>
            <!--Media Recuperacao Semestral, fundamental 1 e fundamenal 2-->
            <td  v-if="tipoTabela == 1 && (periodoSelecionado == 2 || periodoSelecionado == 4) && estiloTabelaEJA == null">
              <div v-if="(periodoSelecionado == 2 && (record.mostrarPeriodo1 || record.mostrarPeriodo2)) || (periodoSelecionado == 4 && (record.mostrarPeriodo3 || record.mostrarPeriodo4))" >
                <p v-if="record['mediaSemestre'+(periodoSelecionado == 2 ? 1 : 2)]  >= this.configuracao.info.notas.media" style="text-align: center;color: #5a79ec;font-weight: bold;font-size: 18px;">
                  {{record['mediaSemestre'+(periodoSelecionado == 2 ? 1 : 2)]}}
                </p>
                <p v-if="record['mediaSemestre'+(periodoSelecionado == 2 ? 1 : 2)]  < this.configuracao.info.notas.media"  style="text-align: center;color: #e60e0e;font-weight: bold;font-size: 18px;">
                  {{record['mediaSemestre'+(periodoSelecionado == 2 ? 1 : 2)]}}
                </p>
              </div>
            </td>


            <!-- /////////////////////////////////////////////////////////////////// - EJA - ///////////////////////////////////////////////////////////////-->
            <!--Total, eja semestral -->
            <td v-if="estiloTabelaEJA != null && tipoTabela == 2">
              <p v-if="record['total'+periodoSelecionado] >= configuracao.info.notas.media" style="text-align: center;color: #5a79ec;font-weight: bold;font-size: 18px;">
                {{record['total'+periodoSelecionado]}}
              </p>
              <p v-if="record['total'+periodoSelecionado] < configuracao.info.notas.media"  style="text-align: center;color: #e60e0e;font-weight: bold;font-size: 18px;">
                {{record['total'+periodoSelecionado]}}
              </p>
            </td>

            <!--Total, eja bimestral -->
            <td v-if="estiloTabelaEJA != null && tipoTabela == 1">
              <div v-if="(periodoSelecionado == 1 && record.mostrarPeriodo1 ) || (periodoSelecionado == 2 && record.mostrarPeriodo2 )  ">
                <p v-if="record['media'+periodoSelecionado] >= configuracao.info.notas.media" style="text-align: center;color: #5a79ec;font-weight: bold;font-size: 18px;">
                  {{record['media'+periodoSelecionado]}}
                </p>
                <p v-if="record['media'+periodoSelecionado] < configuracao.info.notas.media"  style="text-align: center;color: #e60e0e;font-weight: bold;font-size: 18px;">
                  {{record['media'+periodoSelecionado]}}
                </p>
              </div>
            </td>


            <!-- recuperacao semestral, eja -->
            <td class="width-10"   v-if="tipoTabela == 1 && periodoSelecionado == 2 && estiloTabelaEJA != null  && this.configuracao.info.tera_recuperacao">
              <div  v-if="isLoadingPage" style="display: flex;">
                <pm-ProgressSpinner style="align-items: center;width:50px;height:50px;" />
              </div>
              <div v-else>
                <div v-if="(periodoSelecionado == 1 && record.aluno_notas_completa.periodos_bloqueados >= 1) ||
                  (periodoSelecionado == 2 && record.aluno_notas_completa.periodos_bloqueados >= 2) ||
                  (periodoSelecionado == 3 && record.aluno_notas_completa.periodos_bloqueados >= 3) ||
                  (periodoSelecionado == 4 && record.aluno_notas_completa.periodos_bloqueados >= 4)">
                  <input type="text" disabled :value="record['recuperacaoSemestre'+(periodoSelecionado == 2 ? 1 : 2)]" class="form-control"/>
                </div>
                <div v-else-if="record['periodo'+periodoSelecionado+'_recuperacao'] == null">
                  <input
                    v-if="!record.aluno_fora_da_turma && !record.nee && acesso.cadastrar"
                    :disabled="encerrado['p'+periodoSelecionado] == 1 || !anoSitucao"
                    min="0" max="100"
                    @blur="adicionarEditarRecuperacao(record.aluno_id,  record['recuperacaoSemestre'+(periodoSelecionado == 2 ? 1 : 2)], 1, null, this.configuracao, periodoSelecionado)"
                    style="background-color: #5f9e8e38;"
                    type="number"
                    class="form-control"
                    v-model="record['recuperacaoSemestre'+(periodoSelecionado == 2 ? 1 : 2)]"
                    placeholder="Digite a nota"
                  >
                  <input type="text" disabled :value="record['recuperacaoSemestre'+(periodoSelecionado == 2 ? 1 : 2)]" class="form-control" v-else/>
                </div>
                <div v-else>
                  <input v-if="!record.aluno_fora_da_turma && acesso.editar"
                    :disabled="encerrado['p'+periodoSelecionado] == 1 || !anoSitucao"
                    min="0" max="100"
                    @blur="adicionarEditarRecuperacao(record.aluno_id, record['recuperacaoSemestre'+(periodoSelecionado == 2 ? 1 : 2)], 2,  record['periodo'+periodoSelecionado+'_recuperacao']['id'], this.configuracao, periodoSelecionado)"
                    style="background-color:rgb(224 214 216);"
                    type="number"
                    class="form-control"
                    v-model="record['recuperacaoSemestre'+(periodoSelecionado == 2 ? 1 : 2)]"
                    placeholder="Digite a nota"
                  >
                  <input type="text" disabled :value="record['recuperacaoSemestre'+(periodoSelecionado == 2 ? 1 : 2)]" class="form-control" v-else/>
                </div>
              </div>
            </td>

            <!-- media, eja -->
            <td  v-if="tipoTabela == 1 && periodoSelecionado == 2 && estiloTabelaEJA != null">
              <div v-if="(periodoSelecionado == 2 && (record.mostrarPeriodo1 || record.mostrarPeriodo2))" >
                <p v-if="record['mediaSemestre'+(periodoSelecionado == 2 ? 1 : 2)]  >= this.configuracao.info.notas.media" style="text-align: center;color: #5a79ec;font-weight: bold;font-size: 18px;">
                  {{record['mediaSemestre'+(periodoSelecionado == 2 ? 1 : 2)]}}
                </p>
                <p v-if="record['mediaSemestre'+(periodoSelecionado == 2 ? 1 : 2)]  < this.configuracao.info.notas.media"  style="text-align: center;color: #e60e0e;font-weight: bold;font-size: 18px;">
                  {{record['mediaSemestre'+(periodoSelecionado == 2 ? 1 : 2)]}}
                </p>
              </div>
            </td>



            <!--Recuperacao bimestral, eja -->
            <td class="width-10"   v-if="tipoTabela == 2 && estiloTabelaEJA != null  && this.configuracao.info.tera_recuperacao">
              <div  v-if="isLoadingPage" style="display: flex;">
                <pm-ProgressSpinner style="align-items: center;width:50px;height:50px;" />
              </div>
              <div v-else>

                <div v-if="(periodoSelecionado == 1 && record.aluno_notas_completa.periodos_bloqueados >= 1) ||
                  (periodoSelecionado == 2 && record.aluno_notas_completa.periodos_bloqueados >= 2) ||
                  (periodoSelecionado == 3 && record.aluno_notas_completa.periodos_bloqueados >= 3) ||
                  (periodoSelecionado == 4 && record.aluno_notas_completa.periodos_bloqueados >= 4)">
                  <input type="text" disabled :value="record['recuperacaoPer'+periodoSelecionado]" class="form-control" />
                </div>
                <div v-else-if="record['periodo'+periodoSelecionado+'_recuperacao'] == null">
                  <input
                    v-if="!record.aluno_fora_da_turma && !record.nee && acesso.editar"
                    :disabled="encerrado['p'+periodoSelecionado] == 1 || !anoSitucao"
                    min="0" max="100"
                    @blur="adicionarEditarRecuperacao(record.aluno_id, record['recuperacaoPer'+periodoSelecionado], 1, null, this.configuracao, periodoSelecionado)"
                    style="background-color: #5f9e8e38;"
                    type="number"
                    class="form-control"
                    v-model="record['recuperacaoPer'+periodoSelecionado]"
                    placeholder="Digite a nota"
                  >
                  <input type="text" disabled :value="record['recuperacaoPer'+periodoSelecionado]" class="form-control" v-else/>
                </div>
                <div v-else>
                  <input v-if="!record.aluno_fora_da_turma && acesso.editar"
                    :disabled="encerrado['p'+periodoSelecionado] == 1 || !anoSitucao"
                    min="0" max="100"
                    @blur="adicionarEditarRecuperacao(record.aluno_id, record['recuperacaoPer'+periodoSelecionado], 2,  record['periodo'+periodoSelecionado+'_recuperacao']['id'], this.configuracao, periodoSelecionado)"
                    style="background-color:rgb(224 214 216);"
                    type="number"
                    class="form-control"
                    v-model="record['recuperacaoPer'+periodoSelecionado]"
                    placeholder="Digite a nota"
                  >
                  <input type="text" disabled :value="record['recuperacaoPer'+periodoSelecionado]" class="form-control" v-else/>
                </div>
              </div>
            </td>

            <!-- media, eja -->
            <td v-if="estiloTabelaEJA != null && tipoTabela == 2">
              <p v-if="record['media'+periodoSelecionado] >= configuracao.info.notas.media" style="text-align: center;color: #5a79ec;font-weight: bold;font-size: 18px;">
                {{record['media'+periodoSelecionado]}}
              </p>
              <p v-if="record['media'+periodoSelecionado] < configuracao.info.notas.media"  style="text-align: center;color: #e60e0e;font-weight: bold;font-size: 18px;">
                {{record['media'+periodoSelecionado]}}
              </p>
            </td>

            <!--recuperacao final final eja -->
            <td style="width: 13%;" v-if="estiloTabelaEJA != null && periodoSelecionado == 2">
              <div  v-if="isLoadingPage" style="display: flex;">
                <pm-ProgressSpinner style="align-items: center;width:50px;height:50px;" />
              </div>
              <div v-else>
                <div v-if="!record.temRecuperacao">
                  <input
                    v-if="!record.aluno_fora_da_turma && !record.nee && acesso.cadastrar"
                    min="0" max="100"
                    @blur="adicionarEditarRecuperacaoFinal(record, record.recuperacaoFinal, 2)"
                    style="background-color: #5f9e8e38;"
                    type="number"
                    class="form-control"
                    v-model="record.recuperacaoFinal"
                    placeholder="Digite a nota"
                  >
                  <input type="text" disabled :value="record.recuperacaoFinal" class="form-control" v-else/>
                </div>
                <div v-else>
                  <input v-if="!record.aluno_notas_completa.aluno_fora_da_turma && acesso.editar"
                    min="0" max="100"
                    @blur="adicionarEditarRecuperacaoFinal(record, record.recuperacaoFinal, 2)"
                    style="background-color:rgb(224 214 216);"
                    type="number"
                    class="form-control"
                    placeholder="Digite a nota"
                    v-model="record.recuperacaoFinal"
                  >
                  <input type="text" disabled :value="record.recuperacaoFinal" class="form-control" v-else/>
                </div>
              </div>
            </td>

            <!-- media final eja -->
            <td class="width-10"  v-if="estiloTabelaEJA != null && periodoSelecionado == 2">
              <h3 v-if="record.mediaFinal >= configuracao.info.notas.media" style="text-align: center;color: #5a79ec;font-weight: bold;font-size: 18px;">
                {{record.mediaFinal}}
              </h3>
              <h3 v-if="record.mediaFinal < configuracao.info.notas.media"  style="text-align: center;color: #e60e0e;font-weight: bold;font-size: 18px;">
                {{record.mediaFinal}}
              </h3>
            </td>

          </tr>
        </tbody>
      </table>
    </div>
  </section>


  <!-- Final -->
  <section v-if="final == true"  class=" resp-notatable">
    <div class="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th class="th_per1 mtwidth">MATRÍCULA</th>
            <th class="th_per1">NOME DO ALUNO</th>

            <th class="th_per1" v-if="tipoTabela == 2 || estiloTabelaEJA != null">1° Período</th>
            <th class="th_per1" v-if="tipoTabela == 2 || estiloTabelaEJA != null" >2° Período</th>

            <th class="th_per1" v-if="tipoTabela == 2 && estiloTabelaEJA == null">3° Período</th>
            <th class="th_per1" v-if="tipoTabela == 2 && estiloTabelaEJA == null">4° Período</th>
            <th class="th_per1" v-if="tipoTabela == 2 && estiloTabelaEJA == null">Total</th>

            <th class="th_per1" v-if="tipoTabela == 1 && estiloTabelaEJA == null">1° Semestre</th>
            <th class="th_per1" v-if="tipoTabela == 1 && estiloTabelaEJA == null">2° Semestre</th>

            <!-- Todos tem recuperacao final, menos o eja -->
            <th class="th_per1 th_per1-porcent"   v-if="estiloTabelaEJA == null && this.configuracao.info.tera_recuperacao">RECUPERAÇÃO FINAL <br>(NOTA ENTRE 0-100)</th>
            <!-- Todos tem media final -->
            <th class="th_per1">MÉDIA FINAL</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(record, index) of this.notasAlunos" :key="index">
            <td class="tdmt">{{record.matricula}}</td>
            <td>
              <div class="container">
                <div class="row">
                  <div class="col-md-12" style="padding-left: 0px;padding-right: 0px;" >
                    <p class="nome-nota-resp">
                      {{record.aluno_completo.nome}} {{record.aluno_completo.sobrenome}}
                    <div v-if="record.aluno_fora_da_turma == true">
                      <span class="badge bg-danger table_style ml-2"  v-if="record.evento_saida[record.evento_saida.length - 1].tipoEvento == 'Transferido'">Transferido - {{record.evento_saida[record.evento_saida.length - 1].data_formato_br}}</span>
                      <span class="badge bg-danger table_style ml-2"  v-if="record.evento_saida[record.evento_saida.length - 1].tipoEvento == 'Evadido'"  >Evadido</span>
                      <span class="badge bg-danger table_style ml-2"  v-if="record.evento_saida[record.evento_saida.length - 1].tipoEvento == 'Faleceu'"  >Falecido</span>
                      <span class="badge bg-danger table_style ml-2"  v-if="record.evento_saida[record.evento_saida.length - 1].tipoEvento == 'Transferencia de Turma'"  > Mudou de turma - {{record.evento_saida[record.evento_saida.length - 1].data_formato_br}}</span>

                      <!-- <va-popover style="cursor: pointer;" class="badge bg-danger table_style ml-2"
                            :message="'Mudou para turma: ' + record.evento_saida[record.evento_saida.length-1].turma_nova.nome" color="danger"
                            v-if="record.evento_saida[record.evento_saida.length - 1].tipoEvento == 'Transferencia de Turma'">
                            Mudou de turma - {{record.evento_saida[record.evento_saida.length - 1].data_formato_br}}
                            </va-popover> -->
                    </div>
                    <span class="badge"  v-if="record.nee">
                      <va-popover
                        class="mr-2 mb-2"
                        message="Adicionar métodos utilizados para avaliar o aluno com NEE."
                        color="primary"
                        placement="right"

                      >
                        <va-button v-if="acesso.cadastrar"  @click="ajustarInfo(record.aluno_id)">
                          NEE
                        </va-button>
                        <va-button v-else >
                          NEE
                        </va-button>
                      </va-popover>
                    </span>
                    </p>
                  </div>
                </div>
              </div>
            </td>




            <!-- eja -->
            <td class="width-10"   v-if="estiloTabelaEJA != null">
              <div v-if="record['media'+1] >= configuracao.info.notas.media">
                <h3 style="text-align: center;color:rgb(90, 121, 236);font-weight: bold;font-size:18px;">
                  {{record['media'+1]}}
                </h3>
              </div>
              <div v-else>
                <h3 style="text-align: center;color:rgb(230, 14, 14);font-weight: bold;font-size:18px;">
                  <b v-if="(record.mostrarPeriodo1)" >
                    {{record['media'+1]}}
                  </b>
                </h3>
              </div>
            </td>
            <td class="width-10"  v-if="estiloTabelaEJA != null">
              <div v-if="record['media'+2] >= configuracao.info.notas.media">
                <h3 style="text-align: center;color:rgb(90, 121, 236);font-weight: bold;font-size:18px;">
                  {{record['media'+2]}}
                </h3>
              </div>
              <div v-else>
                <h3 style="text-align: center;color:rgb(230, 14, 14);font-weight: bold;font-size:18px;" >
                  <b v-if="(record.mostrarPeriodo2)" >
                    {{record['media'+2]}}
                  </b>
                </h3>
              </div>
            </td>

            <!-- bimestral -->
            <td class="width-10"  v-if="tipoTabela == 2 && estiloTabelaEJA == null">
              <div v-if="record.media1 >= this.configuracao.info.notas.media">
                <h3 style="text-align: center;color:rgb(90, 121, 236);font-weight:bold;font-size:18px;" >
                  {{record.media1}}
                </h3>
              </div>
              <div v-else>
                <h3 style="text-align: center;color:rgb(230, 14, 14);font-weight: bold;font-size:18px;" >
                  <b v-if="(record.mostrarPeriodo1)" >
                    {{record.media1}}
                  </b>
                </h3>
              </div>
            </td>
            <td class="width-10"  v-if="tipoTabela == 2 && estiloTabelaEJA == null">
              <div v-if="record.media2 >= this.configuracao.info.notas.media">
                <h3 style="text-align: center;color:rgb(90, 121, 236);font-weight: bold;font-size:18px;">
                  {{record.media2}}
                </h3>
              </div>
              <div v-else>
                <h3 style="text-align: center;color:rgb(230, 14, 14);font-weight: bold;font-size:18px;">
                  <b v-if="(record.mostrarPeriodo2)" >
                    {{record.media2}}
                  </b>
                </h3>
              </div>
            </td>
            <td class="width-10"  v-if="tipoTabela == 2 && estiloTabelaEJA == null">
              <div v-if="record.media3 >= this.configuracao.info.notas.media">
                <h3 style="text-align: center;color:rgb(90, 121, 236);font-weight: bold;font-size:18px;" >
                  {{record.media3}}
                </h3>
              </div>
              <div v-else>
                <h3 style="text-align: center;color:rgb(230, 14, 14);font-weight: bold;font-size:18px;" >
                  <b v-if="(record.mostrarPeriodo3)" >
                    {{record.media3}}
                  </b>
                </h3>
              </div>
            </td>

            <td class="width-10"  v-if="tipoTabela == 2 && estiloTabelaEJA == null">
              <div v-if="record.media4 >= this.configuracao.info.notas.media">
                <h3 style="text-align: center;color:rgb(90, 121, 236);font-weight: bold;font-size:18px;">
                  {{record.media4}}
                </h3>
              </div>
              <div v-else>
                <h3 style="text-align: center;color:rgb(230, 14, 14);font-weight: bold;font-size:18px;" >
                  <b v-if="(record.mostrarPeriodo4)" >
                    {{record.media4}}
                  </b>
                </h3>
              </div>
            </td>
            <td class="width-10"   v-if="tipoTabela == 2 && estiloTabelaEJA == null">
              <div v-if="record.total >= 200">

                <h3 style="text-align: center;color:rgb(90, 121, 236);font-weight: bold;font-size:18px;">
                  {{record.total}}
                </h3>
              </div>
              <div v-else>
                <h3 style="text-align: center;color:rgb(230, 14, 14);font-weight: bold;font-size:18px;" >
                  {{record.total}}
                </h3>
              </div>
            </td>

            <!-- semestral -->

            <td class="width-10"    v-if="tipoTabela == 1 &&  estiloTabelaEJA == null">
              <div v-if="record.mediaSemestre1 >= configuracao.info.notas.media">
                <h3 style="text-align: center;color:rgb(90, 121, 236);font-weight: bold;font-size:18px;">
                  {{record.mediaSemestre1}}
                </h3>
              </div>
              <div v-else>
                <h3 style="text-align: center;color:rgb(230, 14, 14);font-weight: bold;font-size:18px;" >
                  <b v-if="(record.mostrarPeriodo1 || record.mostrarPeriodo2)" >
                    {{record.mediaSemestre1}}
                  </b>
                </h3>
              </div>
            </td>
            <td class="width-10"   v-if="tipoTabela == 1 && estiloTabelaEJA == null">
              <div v-if="record.mediaSemestre2 >= configuracao.info.notas.media">
                <h3 style="text-align: center;color:rgb(90, 121, 236);font-weight: bold;font-size:18px;">
                  {{record.mediaSemestre2}}
                </h3>
              </div>
              <div v-else>
                <h3 style="text-align: center;color:rgb(230, 14, 14);font-weight: bold;font-size:18px;" >
                  <b v-if="(record.mostrarPeriodo3 || record.mostrarPeriodo4)" >
                    {{record.mediaSemestre2}}
                  </b>
                </h3>
              </div>
            </td>
            <td class="width-10"  v-if="estiloTabelaEJA == null && this.configuracao.info.tera_recuperacao">
              <div v-if="!record.temRecuperacao">
                <input
                  :disabled="!anoSitucao"
                  v-if="!record.aluno_fora_da_turma && acesso.cadastrar"
                  min="0" max="100"
                  @blur="adicionarEditarRecuperacaoFinal(record, record.recuperacaoFinal, 2)"
                  style="background-color: #5f9e8e38;"
                  type="number"
                  class="form-control"
                  v-model="record.recuperacaoFinal"
                  placeholder="Digite a nota"
                >
                <input type="text" disabled :value="record.recuperacaoFinal" class="form-control" v-else/>
              </div>
              <div v-else>
                <input v-if="!record.aluno_fora_da_turma && acesso.editar"
                  min="0" max="100"
                  @blur="adicionarEditarRecuperacaoFinal(record, record.recuperacaoFinal, 2)"
                  style="background-color:rgb(224 214 216);"
                  type="number"
                  class="form-control"
                  placeholder="Digite a nota"
                  v-model="record.recuperacaoFinal"
                >
                <input type="text" disabled :value="record.recuperacaoFinal" class="form-control" v-else/>
              </div>
            </td>
            <td class="width-10" style=" text-align:center;" v-if="record.aluno_notas_completa != undefined">
              <h3 v-if="record.mediaFinal >= this.configuracao.info.notas.media && !record.aluno_notas_completa.dependencia && !record.aluno_notas_completa.nota_alterada_ata" style="text-align: center;color: #5a79ec;font-weight: bold;">
                {{record.mediaFinal}}
              </h3>
              <h3 v-else-if="record.mediaFinal < this.configuracao.info.notas.media && !record.aluno_notas_completa.dependencia && !record.aluno_notas_completa.nota_alterada_ata" style="text-align: center;color:#e41e1e;font-weight: bold;">
                {{record.mediaFinal}}
              </h3>
              <va-popover  v-else-if="record.aluno_notas_completa.dependencia || record.aluno_notas_completa.nota_alterada_ata"
                class="mr-12 mb-12"
                placement="left"
                :message="record.aluno_notas_completa.info_ata"
              >

                <h3 style="text-align:center;color:#eab308;font-weight: bold;">
                  {{record.mediaFinal}}
                </h3>

              </va-popover>
            </td>
            <td class="width-10" style=" text-align:center;" v-else>

            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>

  <pm-Dialog header="Histórico de entrada(s) e saída(s) da turma"  v-model:visible="showModalEntradaESaida" :style="{ width: '15vw' }" :breakpoints="{ '960px': '75vw', '641px': '100vw' }">
    <div v-for="item in historicoEntradaESaida"  :key="item.id">
      <p>{{ item.evento }}: {{ item.data_evento_br }}</p>
      <hr>
    </div>
  </pm-Dialog>




  <pm-Dialog :header="'Histórico de notas do aluno(a) ' + historicoDeNotas.nome + ' no '  + periodoSelecionado + 'º Período em outra(s) turma(s)' "  v-model:visible="showModalHistoricoDeNotas" :style="{ width: '45vw' }" :breakpoints="{ '960px': '75vw', '641px': '100vw' }">
    <pm-DataTable
      v-if="historicoDeNotas.length"
      :value="historicoDeNotas"
      filterDisplay="menu"
      responsiveLayout="scroll"
      :rows="40"
    >
      <pm-Column
        field="escola"
        header="Escola"
        style="font-size: 14px; vertical-align: middle !important"
      >
        <template #body="{ data }">
          <p>
            {{data.escola.nome}}
          </p>
        </template>
      </pm-Column>

      <pm-Column
        field="serie"
        header="Série"
        style="font-size: 14px; vertical-align: middle !important"
      >
        <template #body="{ data }">
          <p>
            {{data.serie.nome}}
          </p>
        </template>
      </pm-Column>

      <pm-Column
        field="turma"
        header="Turma"
        style="font-size: 14px; vertical-align: middle !important"
      >
        <template #body="{ data }">
          <p>
            {{data.turma.nome}}
          </p>
        </template>
      </pm-Column>
      <pm-Column
        v-if="configuracao.info.tera_simulado"
        field="simulado"
        :header="configuracao.info.simulado_nome_exib"
        style="font-size: 14px; vertical-align: middle !important"
      >
        <template #body="{ data }">
          <p>{{data.simulado}}</p>
        </template>
      </pm-Column>

      <pm-Column
        v-if="configuracao.info.tera_atividades"
        field="ativ_diversificada"
        :header="configuracao.info.atividades_nome_exib"
        style="font-size: 14px; vertical-align: middle !important"
      >

        <template #body="{ data }">
          <p>{{data.atividade}}</p>
        </template>
      </pm-Column>
      <pm-Column
        v-if="configuracao.info.tera_avaliacao"
        field="avaliacao"
        :header="configuracao.info.avaliacao_nome_exib"
        style="font-size: 14px; vertical-align: middle !important"
      >
        <template #body="{ data }">
          <p>{{data.avaliacao}}</p>
        </template>
      </pm-Column>

      <pm-Column
        v-if="(tipoTabela == 2 && estiloTabelaEJA == null && this.configuracao.info.tera_recuperacao) || (tipoTabela == 2 && estiloTabelaEJA != null)"
        field="total"
        header="RECUPERAÇÃO PARALELA"
        style="font-size: 14px; vertical-align: middle !important"
      >
        <template #body="{ data }">
          <p>{{data.recuperacao}}</p>
        </template>
      </pm-Column>

      <pm-Column
        v-if="(tipoTabela == 1 && periodoSelecionado == 2 && estiloTabelaEJA == null  && configuracao.info.tera_recuperacao) || (tipoTabela == 1 && periodoSelecionado == 4 && estiloTabelaEJA == null  && this.configuracao.info.tera_recuperacao)"
        field="total"
        header="RECUPERAÇÃO SEMESTRAL"
        style="font-size: 14px; vertical-align: middle !important"
      >
        <template #body="{ data }">
          <p>{{data.recuperacao}}</p>
        </template>
      </pm-Column>
    </pm-DataTable>
    <p v-if="!historicoDeNotas.length">
      O aluno não possui histórico de notas em nenhuma outra turma.
    </p>
  </pm-Dialog>



</template>

<script>


import { Nota } from "@/class/nota";
import { Turma } from "@/class/turma";


export default {
  name: "PeriodoTable",
  props: {
    displayedRecords:[],
    ajustarInfo:[],
    acesso:[],
    periodoSelecionado:'',
    encerrado:[],
    configuracao:null,
    estiloTabelaEJA:null,

    //nee
    formAlunoComNEE: null,
    showModal:false,

    final:false,
    isLoadingPage:false,
  },
  data() {
    return {
      calendario: "",
      tipoTabela:0,
      notasAlunos:[],
      historicoEntradaESaida: [],
      showModalEntradaESaida: false,
      historicoDeNotas: [],
      showModalHistoricoDeNotas: false,
      anoSitucao:0
    };
  },
  methods: {


    buscarNotasHistorico(items) {
      const info = {
        periodo: Number(this.periodoSelecionado),
        aluno_id: items.aluno_id,
        disciplina_id: items.disciplina_id,
        turma_atual_id: items.turma_id
      }
      Nota.buscarHistorioDeNotasDeAluno(info).then((resp) => {
        this.historicoDeNotas = resp.data;
        this.historicoDeNotas.nome = items.aluno_completo.nome + ' ' + items.aluno_completo.sobrenome;
        this.showModalHistoricoDeNotas = true;

      }).catch((err) => {
          this.$vaToast.init({
              message: "Problema ao buscar os dados do aluno!",
              iconClass: 'fa-star-o',
              position: 'top-right',
              duration: 3000,
              color: 'danger',
              fullWidth: false,
          });
        }).finally(() => {
      });

    },
    buscarDadosDeEntradaESaidaAluno(items) {
      this.historicoEntradaESaida = [];
      this.historicoEntradaESaida = items;
      this.showModalEntradaESaida = true;
    },

    verificarEditarNee(n){
      this.$emit('verificarEditarNee', n);
    },
    alunocomNEE(n){
      this.$emit('alunocomNEE', n);
    },

    ajustarInfo(aluno_id){
      this.$emit('ajustarInfo', aluno_id);
    },
    adicionarEditarSimulado(aluno_id_selecionado, nota, qualTipo, idSimulado, configuracao, periodoSelecionado){
      this.$emit('adicionarEditarSimulado', aluno_id_selecionado, nota, qualTipo, idSimulado, configuracao,periodoSelecionado);
    },
    adicionarEditarAtividade(aluno_id_selecionado, nota, qualTipo, idAtividade, configuracao, periodoSelecionado){
      this.$emit('adicionarEditarAtividade', aluno_id_selecionado, nota, qualTipo, idAtividade, configuracao, periodoSelecionado);
    },
    adicionarEditarAvaliacao(aluno_id_selecionado, nota, qualTipo, idAvaliacao, configuracao, periodoSelecionado){
      this.$emit('adicionarEditarAvaliacao', aluno_id_selecionado, nota, qualTipo, idAvaliacao, configuracao, periodoSelecionado);
    },
    adicionarEditarRecuperacao(aluno_id_selecionado, nota, qualTipo, idRecuperacao, configuracao, periodoSelecionado){
      this.$emit('adicionarEditarRecuperacao', aluno_id_selecionado, nota, qualTipo, idRecuperacao, configuracao, periodoSelecionado);
    },
    mudarParaRetidoOuDependencia(record, tipo){
      this.$emit('mudarParaRetidoOuDependencia', record, tipo);
    },
    adicionarEditarRecuperacaoFinal(record, recuperacao, tipo){
      this.$emit('adicionarEditarRecuperacaoFinal', record, recuperacao, tipo);
    },


  },
  beforeMount() {
    this.anoSitucao = parseInt(sessionStorage.getItem("anoSitucao"));
    this.notasAlunos = this.displayedRecords;
    console.log("WWWWWWWWWWWWWWWWWWWWWWWw", this.configuracao);
    if (Object.keys(this.configuracao).length === 0) {
      this.$vaToast.init({
          message: "O calendário precisar ser configurado antes de lançar notas!",
          iconClass: 'fa-star-o',
          position: 'top-right',
          color:'warning',
          duration: 3000,
          fullWidth: false,
      });
      return false;
    }
    this.tipoTabela = (this.configuracao.info.notas.recuperacao_bimestral == 0 ? 1 : 2);
    for (const el of this.notasAlunos) {//mostrar um traço no periodo 1
            //mostrar um traço no periodo 1
        if ((el.periodo1_simulado == null && el.periodo1_atividades == null && el.periodo1_avaliacao == null) && el.aluno_notas_completa.periodos_bloqueados < 1) {
          el.mostrarPeriodo1 = false;
        }
        else {
          el.mostrarPeriodo1 = true;
        }
        //mostrar um traço no periodo 2
        if ((el.periodo2_simulado == null && el.periodo2_atividades == null && el.periodo2_avaliacao == null) && el.aluno_notas_completa.periodos_bloqueados < 2) {
          el.mostrarPeriodo2 = false;
        }
        else {
          el.mostrarPeriodo2 = true;
        }
        //mostrar um traço no periodo 3
        if ((el.periodo3_simulado == null && el.periodo3_atividades == null && el.periodo3_avaliacao == null) && el.aluno_notas_completa.periodos_bloqueados < 3)  {
          el.mostrarPeriodo3 = false;
       }
        else {
          el.mostrarPeriodo3 = true;
        }
        //mostrar um traço no periodo 4
        if ((el.periodo4_simulado == null && el.periodo4_atividades == null && el.periodo4_avaliacao == null) && el.aluno_notas_completa.periodos_bloqueados < 4) {
          el.mostrarPeriodo4 = false;
        }
        else {
          el.mostrarPeriodo4 = true;
        }
    }

    if (this.notasAlunos.length) {
      Turma.obtemUm(this.notasAlunos[0].turma_id).then((resp) => {
          const turmaDados = resp.data;
          if (turmaDados.semestre == 2) {
            this.encerrado['p1'] = this.encerrado['p3'];
            this.encerrado['p2'] = this.encerrado['p4'];
          }
      }).catch((err) => {
        }).finally(() => {
      });
    }
    //this.ordernarNomes();





  },
};
</script>


<style>
  @media(max-width:576px){
    .va-modal__container{
      width:100% !important;
      height:45%;
    }
    .va-modal--mobile-fullscreen {
      min-height: 0vh!important;
    }
  }
</style>

<style scoped>

  .nome-nota-resp {
    border: 0;
    box-sizing: border-box;
    font-size: 100%;
    margin: 0;
    padding: 0;
    vertical-align: baseline;
  }
  .th_per1{
    background-color: #f8f9fa;
    color: #495057;
    border-right: 1px solid #e9ecef;
    border-bottom: 2px solid #e9ecef;
    text-align: center;
    padding: 1rem 1rem;
    font-weight: 600;
    vertical-align: middle;
  }

  .th_esp{
    width:15px;
    background-color: #3f448700;
    border-bottom: 2px solid #fff;
  }
  .cabecario-turma{
    background-color:#4da4e9;
    color: #fff;
    margin-right: 10px;
    padding: 8px;
    border-radius: 5px;
    text-transform: uppercase;
  }

  .tbl_situacao_apv{
    font-weight: 600;
    color: #539b53;
  }
  .tbl_situacao_rep{
    font-weight: 600;
    color: #b93b3b;
  }
</style>

<style scoped>

  @media(max-width:500px){
    .cabecario-turma{
      display: inline-grid;
      margin-bottom: 5px;
      font-size: 12px;
    }
  }

  .col-icon-univer{
    padding:0px;padding-top: 0px;padding-left: 10px;width: 4%;
  }
  @media(max-width:500px){
    .col-icon-univer{
      width: 15%;
    }
    .col-cturm{
      width: 80%;
    }
  }

  .escolha-nome-disciplina{
    font-size: 23px;margin-top: 10px;padding-bottom: 15px;
  }

  @media(max-width:500px){
     .escolha-nome-disciplina{
       font-size:16px;
     }
  }

  .mtwidth{
    width:10%;
  }
  .tdmt{
    text-align: center;font-weight: 800;color: #2b72fd;
  }

  tr td{
    vertical-align: middle;
  }


</style>


<style lang="scss" scoped>
  .table-example--pagination {
    padding-top: 20px;
    text-align: center;
    text-align: -webkit-center;
  }
  .th_prf{
    background-color: #0067a7 !important;
    color: #fff;
    text-align: left;
    padding: 15px !important;
    padding-left: 25px !important;
    border: 1px solid;
  }
  .th_prf1{
    background-color:  #2e92d1 !important;
    color: #fff;
    text-align: left;
    padding: 15px !important;
    padding-left: 25px !important;
    border: 1px solid;
  }
  .tbody_prf{
    border-top: 0px solid currentColor !important;
  }
</style>

<style>
 .compl{font-size: 12px !important;}
</style>
