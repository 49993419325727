import { Nota } from "@/class/nota";
import { CalcularNota } from "@/class/calcularNotas";

export class FuncoesEmComum {
    static async listarNotasFunction(records, configuracaoRecuperacaoBimestral, configuracao, mediaGeral, tipoTabela, estiloTabelaEJA) {

      if (configuracaoRecuperacaoBimestral) {
        for (var i = 0; i < records.length; i++) {
          //Eh um que nao esta na turma
          if (records[i].aluno_fora_da_turma == true) continue;

          //verificar se existe recuperacao
          if (records[i].aluno_notas_completa) {
            if (
              records[i].aluno_notas_completa.recuperacao_final != null
            ) {
              records[i].temRecuperacao = 1;
            } else {
              records[i].temRecuperacao = 0;
            }
          }
          //Primeiro periodo
          if (records[i].periodo1_atividades != null) {
            records[i].ativPer1 =
              records[i].periodo1_atividades[0].atividade;
          } else {
            records[i].ativPer1 = "";
          }
          if (records[i].periodo1_avaliacao != null) {
            records[i].avaliacaoPer1 =
              records[i].periodo1_avaliacao.avaliacao;
          } else {
            records[i].avaliacaoPer1 = "";
          }
          if (records[i].periodo1_simulado != null) {
            records[i].simuladoPer1 =
              records[i].periodo1_simulado.simulado;
          } else {
            records[i].simuladoPer1 = "";
          }
          if (records[i].periodo1_recuperacao != null) {
            records[i].recuperacaoPer1 =
              records[i].periodo1_recuperacao.recuperacao;
          } else {
            records[i].recuperacaoPer1 = "";
          }
          records[i].total1 = 0;
          // if (!estiloTabelaEJA) {
            if (records[i].ativPer1 != "")
              records[i].total1 = records[i].ativPer1;
            if (records[i].simuladoPer1 != "")
              records[i].total1 += records[i].simuladoPer1;
            if (records[i].avaliacaoPer1 != "")
              records[i].total1 += records[i].avaliacaoPer1;
          // }

          records[i].media1 = 0;
          //receber nota pre definida
          if (records[i].aluno_notas_completa['periodos_bloqueados'] >= 1) {
            records[i].media1 = records[i].aluno_notas_completa['periodo_1'];
          }
          else {
            records[i].media1 = await CalcularNota.calcMediaBimestral(
              records[i],
              1,
              configuracao.info.notas.notas_substituir_recup,
              configuracao
            );
          }

          //Segundo periodo
          if (records[i].periodo2_atividades != null) {
            records[i].ativPer2 =
              records[i].periodo2_atividades[0].atividade;
          } else {
            records[i].ativPer2 = "";
          }
          if (records[i].periodo2_avaliacao != null) {
            records[i].avaliacaoPer2 =
              records[i].periodo2_avaliacao.avaliacao;
          } else {
            records[i].avaliacaoPer2 = "";
          }

          if (records[i].periodo2_simulado != null) {
            records[i].simuladoPer2 =
              records[i].periodo2_simulado.simulado;
          } else {
            records[i].simuladoPer2 = "";
          }
          if (records[i].periodo2_recuperacao != null) {
            records[i].recuperacaoPer2 =
              records[i].periodo2_recuperacao.recuperacao;
          } else {
            records[i].recuperacaoPer2 = "";
          }
          records[i].total2 = 0;
          // if (!estiloTabelaEJA) {
            if (records[i].ativPer2 != "")
              records[i].total2 = parseInt(records[i].ativPer2);
            if (records[i].simuladoPer2 != "")
              records[i].total2 += parseInt(
                records[i].simuladoPer2
              );
            if (records[i].avaliacaoPer2 != "")
              records[i].total2 += parseInt(
                records[i].avaliacaoPer2
              );
          //}



          records[i].media2 = 0;
          //receber nota pre definida
          if (records[i].aluno_notas_completa['periodos_bloqueados'] >= 2) {
            records[i].media2 = records[i].aluno_notas_completa['periodo_2'];
          }
          else {
            records[i].media2 = await CalcularNota.calcMediaBimestral(
              records[i],
              2,
              configuracao.info.notas.notas_substituir_recup,
              configuracao
            );
          }
       
          if (tipoTabela != 3) {
            //Terceiro periodo
            if (records[i].periodo3_atividades != null) {
              records[i].ativPer3 =
                records[i].periodo3_atividades[0].atividade;
            } else {
              records[i].ativPer3 = "";
            }
            if (records[i].periodo3_avaliacao != null) {
              records[i].avaliacaoPer3 =
                records[i].periodo3_avaliacao.avaliacao;
            } else {
              records[i].avaliacaoPer3 = "";
            }
            if (records[i].periodo3_simulado != null) {
              records[i].simuladoPer3 =
                records[i].periodo3_simulado.simulado;
            } else {
              records[i].simuladoPer3 = "";
            }
            if (records[i].periodo3_recuperacao != null) {
              records[i].recuperacaoPer3 =
                records[i].periodo3_recuperacao.recuperacao;
            } else {
              records[i].recuperacaoPer3 = "";
            }


            records[i].media3 = 0;
            //receber nota pre definida
            if (records[i].aluno_notas_completa['periodos_bloqueados'] >= 3) {
              records[i].media3 = records[i].aluno_notas_completa['periodo_3'];
            }
            else {
              records[i].media3 = await CalcularNota.calcMediaBimestral(
                records[i],
                3,
                configuracao.info.notas.notas_substituir_recup,
                configuracao
              );
            }
            //Quarto periodo
            if (records[i].periodo4_atividades != null) {
              records[i].ativPer4 =
                records[i].periodo4_atividades[0].atividade;
            } else {
              records[i].ativPer4 = "";
            }
            if (records[i].periodo4_avaliacao != null) {
              records[i].avaliacaoPer4 =
                records[i].periodo4_avaliacao.avaliacao;
            } else {
              records[i].avaliacaoPer4 = "";
            }
            if (records[i].periodo4_simulado != null) {
              records[i].simuladoPer4 =
                records[i].periodo4_simulado.simulado;
            } else {
              records[i].simuladoPer4 = "";
            }
            if (records[i].periodo4_recuperacao != null) {
              records[i].recuperacaoPer4 =
                records[i].periodo4_recuperacao.recuperacao;
            } else {
              records[i].recuperacaoPer4 = "";
            }


            records[i].media4 = 0;
            //receber nota pre definida
            if (records[i].aluno_notas_completa['periodos_bloqueados'] >= 4) {
              records[i].media4 = records[i].aluno_notas_completa['periodo_4'];
            }
            else {
              records[i].media4 = await CalcularNota.calcMediaBimestral(
                records[i],
                4,
                configuracao.info.notas.notas_substituir_recup,
                configuracao
              );
            }
            records[i].total =
            Number(records[i].media1) +
              Number(records[i].media2) +
                Number(records[i].media3) +
                  Number(records[i].media4);
          } else {
            records[i].total =
              Number(records[i].media1) +  Number(records[i].media2);
          }
          records[i].mediaFinal =
            await CalcularNota.caclMediaFinalTipoBimestral(
              records[i].total,
              records[i],
              tipoTabela
            );
          if (records[i].aluno_notas_completa_existe) {
            records[i].recuperacaoFinal =
              records[i].aluno_notas_completa.recuperacao_final;
          } else {
            records[i].recuperacaoFinal = "";
          }
        }
        let notaCompleta = [];
        //Sempre atualizar a nota completa
        for (var i = 0; i < records.length; i++) {
          //Eh um que nao esta na turma
          if (records[i].aluno_fora_da_turma == true) {
            continue;
          }
          let novo = {
            aluno_notas_id: records[i].aluno_notas_id,
            disciplina_id: records[i].disciplina_id,
            periodo_1: String(
              Math.max(
                records[i].media1,
                records[i].recuperacaoPer1
              )
            ),
            periodo_2: String(
              Math.max(
                records[i].media2,
                records[i].recuperacaoPer2
              )
            ),
            periodo_3: String(
              Math.max(
                records[i].media3,
                records[i].recuperacaoPer3
              )
            ),
            periodo_4: String(
              Math.max(
                records[i].media4,
                records[i].recuperacaoPer4
              )
            ),
            recuperacao_final: records[i].recuperacaoFinal,
            media_final: String(records[i].mediaFinal),
            passou: records[i].aluno_notas_completa.passou == 2 ? 2 : records[i].mediaFinal >= mediaGeral,
          };
          // if (novo.passou == 1) {
          //   records[i].passou = 1;
          //   novo.passou = 1;
          //   novo.dependencia = 0;
          //   //ok
          // } else if (records[i].aluno_notas_completa.dependencia) {
          //   //nao alterar
          //   records[i].passou = 2;
          //   novo.passou = 2;
          //   novo.dependencia = 1;
          // } else if (!records[i].aluno_notas_completa.dependencia) {
          //   records[i].passou = 0;
          //   novo.passou = 0;
          //   novo.dependencia = 0;
          // }
          notaCompleta.push(novo);
        }
        let data1 = "";
        if (notaCompleta.length)
          data1 = await Nota.adicionarDeTodosAlunos(notaCompleta);
      } 
      else if (!configuracaoRecuperacaoBimestral) {
        for (var i = 0; i < records.length; i++) {
          //Eh um que nao esta na turma
          if (records[i].aluno_fora_da_turma === true) continue;
          //verificar se existe recuperacao
          if (records[i].aluno_notas_completa) {
            if (
              records[i].aluno_notas_completa.recuperacao_final != null
            ) {
              records[i].temRecuperacao = 1;
            } else {
              records[i].temRecuperacao = 0;
            }
          }
          //Primeiro periodo
          if (records[i].periodo1_atividades != null) {
            records[i].ativPer1 =
              records[i].periodo1_atividades[0].atividade;
          } else {
            records[i].ativPer1 = "";
          }
          if (records[i].periodo1_avaliacao != null) {
            records[i].avaliacaoPer1 =
              records[i].periodo1_avaliacao.avaliacao;
          } else {
            records[i].avaliacaoPer1 = "";
          }
          if (records[i].periodo1_simulado != null) {
            records[i].simuladoPer1 =
              records[i].periodo1_simulado.simulado;
          } else {
            records[i].simuladoPer1 = "";
          }

          records[i].media1 = 0;
          //receber nota pre definida
          if (records[i].aluno_notas_completa['periodos_bloqueados'] >= 1) {
            records[i].media1 = records[i].aluno_notas_completa['periodo_1'];

          }
          else {
            records[i].media1 =
              await CalcularNota.calcTotalPeriodoSemestral(
                records[i],
                1,
                configuracao
              );
          }

          //Segundo periodo
          if (records[i].periodo2_atividades != null) {
            records[i].ativPer2 =
              records[i].periodo2_atividades[0].atividade;
          } else {
            records[i].ativPer2 = "";
          }
          if (records[i].periodo2_avaliacao != null) {
            records[i].avaliacaoPer2 =
              records[i].periodo2_avaliacao.avaliacao;
          } else {
            records[i].avaliacaoPer2 = "";
          }
          if (records[i].periodo2_simulado != null) {
            records[i].simuladoPer2 =
              records[i].periodo2_simulado.simulado;
          } else {
            records[i].simuladoPer2 = "";
          }
          if (records[i].periodo2_recuperacao != null) {
            records[i].recuperacaoSemestre1 =
              records[i].periodo2_recuperacao.recuperacao;
          } else {
            records[i].recuperacaoSemestre1 = "";
          }


          records[i].media2 = 0;
          //receber nota pre definida
          if (records[i].aluno_notas_completa['periodos_bloqueados'] >= 2) {
            records[i].media2 = records[i].aluno_notas_completa['periodo_1'];
          }
          else {
            records[i].media2 =
              await CalcularNota.calcTotalPeriodoSemestral(
                records[i],
                2,
                configuracao
              );
          }

          records[i].periodo1Nota = records[i].media1;
          records[i].periodo2Nota = records[i].media2;

          if (records[i].recuperacaoSemestre1 != "") {
            var answer = await CalcularNota.aRecuperacaoSubstituiOQue(
              records[i].periodo1Nota,
              records[i].periodo2Nota,
              records[i].recuperacaoSemestre1
            );
            if (answer == 1) {
              records[i].periodo1Nota =
                records[i].recuperacaoSemestre1;
              records[i].periodo2Nota =
                records[i].recuperacaoSemestre1;
            } else if (answer == 2) {
              records[i].periodo1Nota =
                records[i].recuperacaoSemestre1;
            } else if (answer == 3) {
              records[i].periodo2Nota =
                records[i].recuperacaoSemestre1;
            }
          }
          records[i].mediaSemestre1 =
            await CalcularNota.calcMediaSemestral(
              records[i].media1,
              records[i].media2,
              records[i].recuperacaoSemestre1
            );

          //Terceiro periodo
          if (records[i].periodo3_atividades != null) {
            records[i].ativPer3 =
              records[i].periodo3_atividades[0].atividade;
          } else {
            records[i].ativPer3 = "";
          }
          if (records[i].periodo3_avaliacao != null) {
            records[i].avaliacaoPer3 =
              records[i].periodo3_avaliacao.avaliacao;
          } else {
            records[i].avaliacaoPer3 = "";
          }
          if (records[i].periodo3_simulado != null) {
            records[i].simuladoPer3 =
              records[i].periodo3_simulado.simulado;
          } else {
            records[i].simuladoPer3 = "";
          }




          records[i].media3 = 0;
          //receber nota pre definida
          if (records[i].aluno_notas_completa['periodos_bloqueados'] >= 3) {
            records[i].media3 = records[i].aluno_notas_completa['periodo_3'];
          }
          else {
            records[i].media3 =
              await CalcularNota.calcTotalPeriodoSemestral(
                records[i],
                3,
                configuracao
              );
          }

          //Quarto periodo
          if (records[i].periodo4_atividades != null) {
            records[i].ativPer4 =
              records[i].periodo4_atividades[0].atividade;
          } else {
            records[i].ativPer4 = "";
          }
          if (records[i].periodo4_avaliacao != null) {
            records[i].avaliacaoPer4 =
              records[i].periodo4_avaliacao.avaliacao;
          } else {
            records[i].avaliacaoPer4 = "";
          }
          if (records[i].periodo4_simulado != null) {
            records[i].simuladoPer4 =
              records[i].periodo4_simulado.simulado;
          } else {
            records[i].simuladoPer4 = "";
          }
          if (records[i].periodo4_recuperacao != null) {
            records[i].recuperacaoSemestre2 =
              records[i].periodo4_recuperacao.recuperacao;
          } else {
            records[i].recuperacaoSemestre2 = "";
          }


          records[i].media4 = 0;
          //receber nota pre definida
          if (records[i].aluno_notas_completa['periodos_bloqueados'] >= 4) {
            records[i].media4 = records[i].aluno_notas_completa['periodo_4'];
          }
          else {
            records[i].media4 =
              await CalcularNota.calcTotalPeriodoSemestral(
                records[i],
                4,
                configuracao
              );
          }

          records[i].periodo3Nota = records[i].media3;
          records[i].periodo4Nota = records[i].media4;

          if (records[i].recuperacaoSemestre2 != "") {
            var answer = await CalcularNota.aRecuperacaoSubstituiOQue(
              records[i].periodo3Nota,
              records[i].periodo4Nota,
              records[i].recuperacaoSemestre2
            );
            if (answer == 1) {
              records[i].periodo3Nota =
                records[i].recuperacaoSemestre2;
              records[i].periodo4Nota =
                records[i].recuperacaoSemestre2;
            } else if (answer == 2) {
              records[i].periodo3Nota =
                records[i].recuperacaoSemestre2;
            } else if (answer == 3) {
              records[i].periodo4Nota =
                records[i].recuperacaoSemestre2;
            }
          }
          records[i].total =

            Number(records[i].periodo1Nota) +
            Number(records[i].periodo2Nota) +
            Number(records[i].periodo3Nota) +
            Number(records[i].periodo4Nota);

          records[i].mediaSemestre2 =
            await CalcularNota.calcMediaSemestral(
              records[i].media3,
              records[i].media4,
              records[i].recuperacaoSemestre2
            );
          if (records[i].aluno_notas_completa_existe) {
            records[i].recuperacaoFinal =
              records[i].aluno_notas_completa.recuperacao_final;
          } else {
            records[i].recuperacaoFinal = "";
          }
          records[i].mediaFinal =
            await CalcularNota.caclMediaFinalTipoSemestral(
              records[i].total,
              records[i].recuperacaoFinal,
              tipoTabela
            );
        }

        let notaCompleta = [];
        //Sempre atualizar a nota completa
        for (var i = 0; i < records.length; i++) {
          //Eh um que nao esta na turma
          if (records[i].aluno_fora_da_turma == true) continue;
          let novo = {
            aluno_notas_id: records[i].aluno_notas_id,
            disciplina_id: records[i].disciplina_id,
            periodo_1: String(
              Math.max(
                records[i].periodo1Nota,
                records[i].recuperacaoSemestre1
              )
            ),
            periodo_2: String(
              Math.max(
                records[i].periodo2Nota,
                records[i].recuperacaoSemestre1
              )
            ),
            periodo_3: String(
              Math.max(
                records[i].periodo3Nota,
                records[i].recuperacaoSemestre2
              )
            ),
            periodo_4: String(
              Math.max(
                records[i].periodo4Nota,
                records[i].recuperacaoSemestre2
              )
            ),
            recuperacao_final: records[i].recuperacaoFinal,
            media_final: String(records[i].mediaFinal),
            passou: records[i].aluno_notas_completa.passou == 2 ? 2 : records[i].mediaFinal >= mediaGeral,
          };
          // if (novo.passou == 1) {
          //   records[i].passou = 1;
          //   novo.passou = 1;
          //   novo.dependencia = 0;
          //   //ok
          // } else if (records[i].aluno_notas_completa.dependencia) {
          //   //nao alterar
          //   records[i].passou = 2;
          //   novo.passou = 2;
          //   novo.dependencia = 1;
          // } else if (!records[i].aluno_notas_completa.dependencia) {
          //   records[i].passou = 0;
          //   novo.passou = 0;
          //   novo.dependencia = 0;
          // }
          notaCompleta.push(novo);
        }
        let data1 = "";
        if (notaCompleta.length)
          data1 = await Nota.adicionarDeTodosAlunos(notaCompleta);
      }
      return records;
    }

}
